"use strict";

module.exports = function() {
    return {
        restrict: "A",
        require: 'ngModel',
        scope: {
            accountMappingGroups: '=',
            resmanValue: '=',
            maxLength: '@'
        },
        link: function(scope, element, attrs, ngModelCtrl) {

            function getCostCentre(account) {
                if (account && account.includes('-')) {
                    return account.split('-')[0];
                }
                return account;
            }

            
            // Costcentre presence validation
            ngModelCtrl.$validators.hyphen = function(modelValue) {
                return modelValue && modelValue.includes('-');
            };

            // Unique validation - Cost centre must not be unique
            ngModelCtrl.$validators.sameCostCentre = function(modelValue) {
                const costCentre = getCostCentre(modelValue);
                let isSameCostCentre = true;
                if (costCentre)
                {
                    angular.forEach(scope.accountMappingGroups, function(mappingGroup) {
                        angular.forEach(mappingGroup.accountMappings, function(accountMapping) {
                            const otherCostCentre = getCostCentre(accountMapping.account)
                            
                            if (otherCostCentre && otherCostCentre !== costCentre && scope.resmanValue != accountMapping.mapping.resmanValue) {
                                isSameCostCentre = false;
                                return isSameCostCentre;
                            }
                        });
                    });
                }
                return isSameCostCentre;
            };

            // Max length validation
            scope.$watch(() => ngModelCtrl.$modelValue, function(newVal) {
                if (newVal && newVal.length > scope.maxLength) {
                    ngModelCtrl.$setValidity('maxlength', false);
                    ngModelCtrl.$setTouched();
                } else {
                    ngModelCtrl.$setValidity('maxlength', true);
                }
            });

        }
    };
};
